.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  -webkit-transition: opacity 500ms;
  transition: opacity 500ms;
  visibility: visible;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);
}

.popup-1 {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  padding: 50px;
  background-color: #fff;
  border-radius: 20px;
  width: 500px;
  margin: auto;
  -webkit-filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.5));
  -webkit-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}

@media screen and (max-width: 580px) {
  .popup-1 {
    width: 32rem;
    max-width: 95%;
  }
}

.popup-1 .popup-1-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.popup-1 .popup-1-container .popup-defination {
  font-family: Gill-sans-bold;
  font-size: 20px;
  color: #0E1D25;
}

.popup-1 .popup-1-container .popup-detail {
  font-family: Gill-sans-regular;
  font-size: 12px;
  color: #000;
  line-height: 20px;
  padding-top: 2rem;
  text-transform: uppercase;
}

.popup-1 .popup-1-container input {
  font-family: Gill-sans-regular;
  font-size: 12px;
  line-height: 20px;
  color: #575757;
  border: 0;
  outline: 0;
  margin-top: 2rem;
}

.popup-1 .popup-1-container input::-webkit-input-placeholder {
  font-family: Gill-sans-regular;
  font-size: 12px;
  line-height: 20px;
  color: #575757;
}

.popup-1 .popup-1-container input:-ms-input-placeholder {
  font-family: Gill-sans-regular;
  font-size: 12px;
  line-height: 20px;
  color: #575757;
}

.popup-1 .popup-1-container input::-ms-input-placeholder {
  font-family: Gill-sans-regular;
  font-size: 12px;
  line-height: 20px;
  color: #575757;
}

.popup-1 .popup-1-container input::placeholder {
  font-family: Gill-sans-regular;
  font-size: 12px;
  line-height: 20px;
  color: #575757;
}

.popup-1 .popup-1-container .line {
  margin-top: 1px;
  border-bottom: 1px solid #000;
}

.popup-1 .popup-1-container .charcter-limit {
  font-family: Gill-sans-regular;
  font-size: 12px;
  color: #575757;
  text-align: right;
  padding-top: 1rem;
}

.popup-1 .popup-1-container .btn-container {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.popup-1 .popup-1-container .btn-container .btn {
  width: 99px;
  font-size: 11px;
  font-family: Gill-sans-regular;
  background-color: #000;
  color: #fff;
  text-align: center;
  padding: 9px 0;
  border-radius: 10px;
  border: 0;
  outline: 0;
}

.popup-1 .popup-1-container .btn-container .second {
  margin-left: 1rem;
}

.popup-2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media screen and (min-width: 580px) {
  .popup-2 {
    width: 495px;
  }
}
