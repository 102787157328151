button{
    cursor: pointer;
}
.mymoola-header{
   .btn:hover{
    background-color: rgba($color: #575757, $alpha: .6);

   }
}
.mymoola{
    padding-left: 5rem;
    padding-right: 5rem;
    background-color: #000;
    @media screen and (max-width: 650px) {
        padding: 0rem 2rem 0rem 2rem;
    }
}
.border-mollas{
 display: block !important;
 height: 4px;
 width: 90%;
 background-color: #fff;
}
.mymoola-header{
    max-width: 1920px;
    // margin: auto;
    background-color: #000;
    padding: 2.8rem 0rem 2rem 0rem;
    background-color: #000;
    @media screen and (max-width: 650px) {
        padding: 2.8rem 0rem 1rem 0rem;
    }
    border-bottom: 4px solid #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and(max-width:400px){
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    }
    .title{
        color: #fff;
        font-family: Gill-sans-bold;
        font-size: 3.4rem;
        line-height: 3.9rem;

        @media screen and (max-width: 650px) {
            font-size: 2rem;
        }
        @media screen and (max-width: 400px) {
            font-size: 1.5rem;
        }
    }
    .btn{
        width: 14.5rem;
        height: 4.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        background-color: #575757;
        border-radius: 10px;
        outline: none;
        border: 0;
        margin-left: 2.1rem;

        @media only screen and (max-width: 950px){
            width: 5rem;
            height: 5rem;
        }

        @media screen and (max-width: 650px) {
            width: 4rem;
            height: 4rem;
            margin-left: 1.3rem;
        }
        
        span{
            font-size: 1.2rem;
            font-family: Gill-sans-regular;
            letter-spacing: 1px;

            @media only screen and (max-width: 950px){
                display:none;
            }
        }

        img{
            width: 1.9rem;
            margin-left: 8px;

            @media only screen and (max-width: 950px){
                margin-left: 0 !important;
                width: 2rem;
            }

            @media only screen and (max-width: 650px){
                width: 1.5rem;
            }
        }
    }
}

.gallery-container{
    padding: 4.5rem 0rem;
background-color: #000;
    @media screen and (max-width: 650px){
        padding: 4.5rem 0rem;
    }
    

    .row{
        display: flex;

        @media screen and (max-width: 950px) {
            flex-direction: column;
        }

        .contain2-boxes{
            display: flex;

            // @media screen and (max-width: 950px){
            //     width: 100%;
            // }
            .my-diary{
                
                color: #fff;
                width: 46rem;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: #2D2D2D;
                border-radius: 20px;

                @media screen and (max-width: 1366px) and (min-width: 950px) {
                    width: 43rem;
                }

                @media screen and (max-width: 950px){
                    width: 50% !important;
                }


                .heading{
                    font-family: Gill-sans-bold;
                    font-size: 1.6rem;
                    padding-top: 2rem;
                }

                .img-container{
                    border-radius: 20px;
                    width: 25rem;
                    margin-top: 2rem;

                    @media screen and (max-width: 650px) {
                        width: 60%;
                    }

                    img{
                        width: 100%;
                    }
                }

                p{
                    font-family: Gill-sans-regular;
                    font-size:13px;
                    text-transform: uppercase;
                    letter-spacing: 0.5px;
                    text-align: center;
                }

                .balance{
                    padding-top: 1.8rem;
                }

                h5{
                    font-family: Gill-sans-bold;
                    font-size: 1.4rem;
                    letter-spacing: 0.5px;
                }

                .available{
                    padding-top: 4.5rem;
                    @media screen and (max-width: 650px){
                        padding-top: 2rem;
                    }
                }

              
                    @media screen and (max-width: 650px){
                        width: 100%;
                        text-align: center;
                    }
                    .collecta{
                        @media screen and (max-width: 650px) {
                            width: 90%;
                        }
                    }
                    .collect{
                        background-color: #FFB800;
                        margin-top: 2rem;
                        margin-bottom: 4rem;
    
                        @media screen and (max-width: 650px) {
                            width: 100%;
                            margin-top: 1.2rem;
                            margin-bottom: 0;
                        }
    
                        h4{
                            color: #000;
                            font-size: 12px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                        }
                    }
                    .collect:hover{
                        background-color: rgba($color: #FFB800, $alpha: .6);

                    }
                
            }
        }

        .contain-box-2{

            @media screen and (max-width: 950px) {
                // align-items: flex-start !important;
                margin-left: 1rem !important;
                margin-top: 3.5rem;
                
            }
        }
        .column-gal{
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-left: 4.5rem;

            @media screen and (max-width: 950px){
                width: 46%;
            }

            @media screen and (max-width: 650px){
                margin-left: 2.5rem;
            }

            @media screen and (max-width: 330px){
                margin-left: 1.5rem;
            }

            .image-container{
                width: 22rem;

                @media screen and (max-width: 1366px) and (min-width: 950px) {
                    width: 18rem;
                }

                @media screen and (max-width: 950px){
                    width: 100%;
                    // max-width: 30rem;
                }
                img{
                    width: 100%;
                    border-radius: 10px;
                }
            }

            .title{
                padding-top: 1.5rem;
                font-family: Gill-sans-bold;
                font-size: 12px;
                text-align: center;
                color: #fff;
            }

            .btn-container{
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 950px){
                    width: 100%;
                }

                .btn-gray{
                    margin-top: 2rem;
                    background-color: #575757;

                    @media screen and (max-width: 1366px) {
                        width:20rem;
                    }

                    @media screen and (max-width: 950px){
                        width: 100%;
                    }

                    span{
                        color: #fff;
                        letter-spacing: 0.5px;
                    }
                }
            }
            .btn-gray:hover{
                background-color: rgba($color: #575757, $alpha: .6);
            
            }
            .text{
                font-family: Gill-sans-regular;
                font-size: 12px;
                color: #fff;
                padding-top: 2rem;
                letter-spacing: 0.2px;
            }
        }
    }

    // for all the buttons on this page shared class 
    .btn{
        width: 22rem;
        padding: 11px 0;
        text-align: center;
        border: 0;
        outline: 0;
        border-radius: 10px;
        @media screen and (max-width: 650px){
            padding: 8.5px 0;
        }
        
        
        span{
            font-family: Gill-sans-regular;
            font-size: 12px;
            text-transform: uppercase;

        @media screen and (max-width: 650px) {
            font-size: 10px;
        }
        }
    }
}

//header class for the back button
// .back:hover{
//     background-color: rgba($color: #575757, $alpha: .6);

// }
.back{
    border-radius: 10px;
    outline: none;
    border: 0;
    background-color: #575757;
    font-size: 1.2rem !important;
    font-family: Gill-sans-bold !important;
    padding: 1.25rem 2.7rem;
    width: auto !important;
    height: auto !important;

    span{
        @media screen and (max-width: 950px) and (min-width: 550px){
            display: block !important;
            font-size: 1rem;
        }
    }

    img{
        width: 2.2rem !important;
        margin-right: 6px;
        margin-left: 1px !important;
        margin-bottom: 0.8px;
        @media screen and (max-width: 550px){
            margin-left: 1px;
        }
    }
}
