.soon {
  display: -ms-grid;
  display: grid;
  justify-items: center;
  margin-top: 20%;
  margin-bottom: 20%;
}

.soon .soonimg {
  border: 2px solid #fff;
  border-radius: 20px;
}

@media screen and (max-width: 650px) {
  .soon .soonimg {
    width: 80%;
  }
}

.sale-page-body {
  max-width: 1756px;
  padding: 1rem 6rem;
  padding-bottom: 20rem !important;
  margin: auto;
}

@media screen and (min-width: 1756px) {
  .sale-page-body {
    max-width: initial;
    margin: 0;
  }
}

@media screen and (max-width: 650px) {
  .sale-page-body {
    padding: 1rem 2rem;
  }
}

.sale-page-body .container {
  width: 100%;
}

.sale-page-body .container .price-heading {
  display: block;
  color: #fff;
  font-family: Gill-sans-regular;
  font-size: 14px;
  padding-top: 2.5rem;
  letter-spacing: 1px;
}

@media screen and (max-width: 850px) {
  .sale-page-body .container .price-heading {
    display: none;
  }
}

.sale-page-body .container .sale-moolas {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr)[10];
      grid-template-columns: repeat(10, 1fr);
  -webkit-column-gap: 2.6rem;
          column-gap: 2.6rem;
}

@media screen and (max-width: 1280px) {
  .sale-page-body .container .sale-moolas {
    -ms-grid-columns: (1fr)[8];
        grid-template-columns: repeat(8, 1fr);
    -webkit-column-gap: 2.6rem;
            column-gap: 2.6rem;
  }
}

@media screen and (max-width: 950px) {
  .sale-page-body .container .sale-moolas {
    -ms-grid-columns: (1fr)[6];
        grid-template-columns: repeat(6, 1fr);
    -webkit-column-gap: 2rem;
            column-gap: 2rem;
  }
}

@media screen and (max-width: 550px) and (min-width: 0px) {
  .sale-page-body .container .sale-moolas {
    -ms-grid-columns: (1fr)[2];
        grid-template-columns: repeat(2, 1fr);
    -webkit-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }
}

.sale-page-body .container .sale-moolas .column {
  width: 100%;
  margin-top: 4rem;
  color: #fff;
  text-align: center;
}

@media screen and (max-width: 850px) and (min-width: 550px) {
  .sale-page-body .container .sale-moolas .column {
    margin-top: 3.5rem;
  }
}

@media screen and (max-width: 550px) and (min-width: 0px) {
  .sale-page-body .container .sale-moolas .column {
    margin-top: 2.2rem;
  }
}

.sale-page-body .container .sale-moolas .column img {
  width: 100%;
  border-radius: 10px;
}

.sale-page-body .container .sale-moolas .column .name-no {
  font-family: Gill-sans-bold;
  font-size: 12px;
  padding-top: 1.5rem;
  letter-spacing: 0.8px;
}

.sale-page-body .container .sale-moolas .column .price {
  display: block;
  font-family: Gill-sans-regular;
  font-size: 1.2rem;
  padding-top: 2rem;
  letter-spacing: 1px;
}

.sec-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 3em 6em 0em 6em;
}

@media screen and (max-width: 650px) {
  .sec-header {
    padding: 4em 3em;
  }
}

.sec-header .border {
  margin-top: 2em;
  height: 4px;
  width: 100vw;
  background-color: #fff;
}

.sec-header h1 {
  color: #fff;
  font-size: 34px;
  font-family: Gill-sans-bold;
}

.sec-header .btn {
  width: 14.5rem;
  height: 4.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  background-color: #575757;
  border-radius: 10px;
  outline: none;
  border: 0;
  margin-left: 2.1rem;
}

@media only screen and (max-width: 950px) {
  .sec-header .btn {
    width: 5rem;
    height: 5rem;
  }
}

@media screen and (max-width: 650px) {
  .sec-header .btn {
    width: 3rem;
    height: 3rem;
    margin-left: 1.3rem;
  }
}

@media screen and (max-width: 548px) {
  .sec-header .btn {
    margin-top: 2em;
  }
}

.sec-header .btn span {
  font-size: 1.2rem;
  font-family: Gill-sans-regular;
  letter-spacing: 1px;
}

.sec-header .btn img {
  width: 1.9rem;
  margin-left: 8px;
}

@media only screen and (max-width: 950px) {
  .sec-header .btn img {
    margin-left: 0 !important;
    width: 2rem;
  }
}

@media only screen and (max-width: 650px) {
  .sec-header .btn img {
    width: 1.5rem;
  }
}

.sec-header .wallet {
  background-color: #fff;
  width: 19rem;
  height: 4.1rem;
  border-radius: 25px;
}

.sec-header .wallet span {
  color: #000;
  font-family: arial;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: -1px;
  word-spacing: 3.5px;
}

@media screen and (max-width: 950px) {
  .sec-header .wallet span {
    margin-right: 8px;
  }
}

.sec-header .wallet img {
  width: 1.7rem;
  margin-left: 8px;
}
