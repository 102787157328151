.home {
  background-color: #000000;
  height: 100vh;
  overflow: hidden;
}

@media screen and (min-width: 950px) and (max-width: 500000000000000000px) {
  .home {
    overflow: inherit;
  }
}

.home .secondary-header {
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
  margin-right: 4em;
}

.home .secondary-header .btn {
  width: 14.5rem;
  height: 4.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
  background-color: #575757;
  border-radius: 10px;
  outline: none;
  border: 0;
  margin-left: 2.1rem;
}

@media only screen and (max-width: 950px) {
  .home .secondary-header .btn {
    width: 5rem;
    height: 5rem;
  }
}

@media screen and (max-width: 650px) {
  .home .secondary-header .btn {
    width: 3rem;
    height: 3rem;
    margin-left: 1.3rem;
  }
}

.home .secondary-header .btn span {
  font-size: 1.2rem;
  font-family: Gill-sans-regular;
  letter-spacing: 1px;
}

@media only screen and (max-width: 950px) {
  .home .secondary-header .btn span {
    display: none;
  }
}

.home .secondary-header .btn img {
  width: 1.9rem;
  margin-left: 8px;
}

@media only screen and (max-width: 950px) {
  .home .secondary-header .btn img {
    margin-left: 0 !important;
    width: 2rem;
  }
}

@media only screen and (max-width: 650px) {
  .home .secondary-header .btn img {
    width: 1.5rem;
  }
}

.home .secondary-header .wallet {
  background-color: #fff;
  width: 19rem;
  height: 4.1rem;
  border-radius: 25px;
}

@media only screen and (max-width: 950px) {
  .home .secondary-header .wallet {
    display: none;
  }
}

.home .secondary-header .wallet span {
  color: #000;
  font-family: arial;
  font-weight: 700;
  font-size: 1.4rem;
  letter-spacing: -1px;
  word-spacing: 3.5px;
}

.home .secondary-header .wallet img {
  width: 1.7rem;
  margin-left: 8px;
}

.home .home-container {
  text-align: center;
  color: #fff;
}

.home .home-container .heading {
  font-family: Gill-sans-bold;
  font-size: 20px;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  text-transform: uppercase;
}

.home .home-container .hero-container {
  margin-top: 4rem;
  width: 100%;
}

@media screen and (min-width: 850px) {
  .home .home-container .hero-container {
    height: 750px;
    background-image: url("../../assets/images/hero-layered.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    -webkit-transition: background-image 1s ease-in-out;
    transition: background-image 1s ease-in-out;
  }
  .home .home-container .hero-container:hover {
    background-image: url("../../assets/images/Web_Hero_Animate 3 2 (1).png");
  }
}

@media screen and (max-width: 1366px) {
  .home .home-container .hero-container {
    height: 578px;
  }
}

@media screen and (max-width: 850px) {
  .home .home-container .hero-container {
    height: auto;
    max-height: 100vh;
    margin-bottom: -13rem;
  }
}

@media screen and (max-width: 850px) {
  .home .home-container .hero-container .img-container {
    width: 100%;
    height: auto;
    max-height: 90vh;
    background-color: #add7f6;
    background-blend-mode: multiply;
    z-index: 1;
  }
}

@media screen and (max-width: 400px) {
  .home .home-container .hero-container .img-container {
    padding-top: 0.5rem;
  }
}

.home .home-container .hero-container .img-container img {
  display: none;
}

@media screen and (max-width: 850px) {
  .home .home-container .hero-container .img-container img {
    display: block;
    width: 100%;
    height: 100%;
    max-height: 100vh;
  }
}

@media screen and (max-height: 700px) and (min-width: 400px) {
  .home .home-container .hero-container .img-container img {
    width: 90%;
  }
}

@media screen and (max-width: 400px) {
  .home .home-container .hero-container .img-container img {
    margin-left: -4rem;
    width: 108%;
  }
}

.home .home-container .button-covering {
  background-color: #000;
  margin-top: -1px;
}

@media screen and (max-width: 850px) {
  .home .home-container .button-covering {
    background-color: transparent;
  }
}

.home .home-container .button-covering .btn {
  background-color: #575757;
  padding: 1.7rem 2.3rem;
  outline: 0;
  border: 0;
  border-radius: 10px;
  margin-top: 4rem;
  margin-bottom: 20rem;
}

.home .home-container .button-covering .btn .btn-txt {
  color: #fff;
  font-family: Gill-sans-regular;
  font-size: 12px;
  letter-spacing: 1px;
}
