$bold : Gill-sans-bold;
$regular: Gill-sans-regular;
.home{
    background-color: #000000;
    height: 100vh;
    overflow: hidden;
    @media screen and (min-width:950px) and(max-width:500000000000000000px){
        overflow: inherit;

    }
    .secondary-header{
        display: grid;
        justify-content: end;
        margin-right: 4em;
        .btn{
            width: 14.5rem;
            height: 4.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            background-color: #575757;
            border-radius: 10px;
            outline: none;
            border: 0;
            margin-left: 2.1rem;
        
            @media only screen and (max-width: 950px){
                width: 5rem;
                height: 5rem;
            }
        
            @media screen and (max-width: 650px) {
                width: 3rem;
                height: 3rem;
                margin-left: 1.3rem;
            }
            
            span{
                font-size: 1.2rem;
                font-family: Gill-sans-regular;
                letter-spacing: 1px;
        
                @media only screen and (max-width: 950px){
                    display:none;
                }
            }
        
            img{
                width: 1.9rem;
                margin-left: 8px;
        
                @media only screen and (max-width: 950px){
                    margin-left: 0 !important;
                    width: 2rem;
                }
        
                @media only screen and (max-width: 650px){
                    width: 1.5rem;
                }
            }
        }
        .wallet{
            background-color: #fff;
            width: 19rem;
            height: 4.1rem;
            border-radius: 25px;

            @media only screen and (max-width: 950px){
                display: none;
            }

            span{
                color: #000;
                font-family: arial;
                font-weight: 700;
                font-size: 1.4rem;
                letter-spacing: -1px;
                word-spacing: 3.5px;
            }

            img{
                width: 1.7rem;
                margin-left: 8px;
            }
        }
    }
    .home-container{
        text-align: center;
        color: #fff;
    
        .heading{
            font-family: $bold;
            font-size: 20px;
            padding-top: 1rem;
            padding-left: 2rem;
            padding-right: 2rem;
            text-transform: uppercase;
        }

        .hero-container{
            margin-top: 4rem;
            width: 100%;
            
            @media screen and (min-width: 850px) {
                height: 750px;
                background-image: url('../../assets/images/hero-layered.png');
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                transition: background-image 1s ease-in-out;

                &:hover{
                    background-image: url('../../assets/images/Web_Hero_Animate\ 3\ 2\ \(1\).png');
                }
            }

            @media screen and (max-width: 1366px) {
                height: 578px;
            }

            @media screen and (max-width: 850px){
                height: auto;
                max-height: 100vh;
                margin-bottom: -13rem;
            }

            .img-container{
                @media screen and (max-width: 850px){
                    width: 100%;
                    height: auto;
                    max-height: 90vh;
                    background-color: #add7f6;
                    background-blend-mode: multiply;
                    z-index: 1;
                }

                @media screen and (max-width: 400px){
                    padding-top: 0.5rem;
                }

                img{
                    display: none;
    
                    @media screen and (max-width: 850px) {
                        display: block;
                        // width: 90%;
                        width: 100%;
                        height: 100%;
                        max-height: 100vh;
                    }

                    @media screen and (max-height: 700px) and (min-width: 400px){
                        width: 90%;
                    }

                    @media screen and (max-width: 400px){
                        margin-left: -4rem;
                        width: 108%;
                    }
                }
            }

            
        }

        .button-covering{
            background-color: #000;
            margin-top: -1px;

            @media screen and (max-width: 850px) {
                background-color: transparent;
            }

            .btn{
                background-color: #575757;
                padding: 1.7rem 2.3rem;
                outline: 0;
                border: 0;
                border-radius: 10px;
                margin-top: 4rem;
                margin-bottom: 20rem;
    
    
                .btn-txt{
                    color: #fff;
                    font-family: $regular;
                    font-size: 12px;
                    letter-spacing: 1px;
                }
    
            }
        }

        // .covering-bottom{
        //     display: none;

        //     @media screen and (max-width: 850px) {
        //         display: block;
        //         background-color: #000;
        //         margin-top: -16.2rem;
        //         z-index: 9999;
        //     }
        // }
    }
}