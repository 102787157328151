$heavy-rgular: Gill-sans;
$bold : Gill-sans-bold;
$regular: Gill-sans-regular;
.sale-inner-container {
    width: 100%;
    .container-white {
      margin: 3.5rem auto 2.5rem auto;
  
      background-color: #fff;
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.5);
  
      .container {
        max-width: 1756px;
        padding: 5rem 5rem 5.6rem 5.1rem;
        display: flex;
        margin: auto;
  
        @media screen and (max-width: 950px) {
          flex-direction: column;
        }
  
        @media screen and (max-width: 650px) {
          padding: 2rem 0 1rem 0rem;
        }
        .left-part {
          width: 70%;
          display: flex;
  
          @media screen and (max-width: 1140px) {
            width: 60%;
          }
  
          @media screen and (max-width: 950px) {
            width: 100%;
          }
  
          @media screen and (max-width: 650px) {
            flex-direction: column;
            align-items: center;
          }
          .img-container {
            width: 33.5rem;
            height: 33.5rem;
  
            @media screen and (max-width: 1140px) and (min-width: 950px) {
              width: 22rem;
              height: 22rem;
            }
  
            @media screen and (max-width: 950px) {
              width: 31rem;
              height: 31rem;
            }
  
            @media screen and (max-width: 650px) {
              width: 14.2rem;
              height: 14.2rem;
            }
  
            img {
              width: 100%;
              height: 100%;
              border-radius: 10px;
            }
          }
  
          .info {
            margin-left: 5rem;
  
            @media screen and (max-width: 950px) {
              margin-left: 4rem;
            }
  
            @media screen and (max-width: 650px) {
              margin-left: 0;
              margin-top: 2rem;
              text-align: center;
            }
  
            h2 {
              font-family: $bold;
              font-size: 28px;
              color: #000;
  
              @media screen and (max-width: 650px) {
                font-size: 24px;
              }
            }
  
            b {
              display: block;
              font-family: $regular;
              font-size: 15px;
              color: #000;
              padding-top: 2rem;
            }
          }
        }
  
        .right-part {
          width: 30%;
          margin-top: -1rem;
  
          @media screen and (max-width: 1140px) and (min-width: 950px) {
            width: 40%;
          }
  
          @media screen and (max-width: 950px) {
            width: 100%;
            // margin-top: -7rem;
          }
  
          @media screen and (max-width: 650px) {
            margin-top: 0;
            padding-left: 2rem;
          }
          //   imported from the file
  
          .brandings {
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 950px) {
              margin-top: 10.5rem;
              flex-direction: row;
  
              overflow: hidden;
              overflow-x: auto;
              &::-webkit-scrollbar {
                width: 0px;
              }
            }
  
            @media screen and (max-width: 650px) {
              margin-top: 2rem;
            }
          }
  
          // styling the buttons of the popup
          .row-popup {
            display: flex;
            justify-content: flex-end;
  
            .column-popup {
              width: 16rem;
              height: auto;
              background-color: #000;
              color: #fff;
              border-radius: 10px;
              margin-top: 1.2rem;
              margin-left: 1rem;
              padding: 0 1rem;
  
              @media screen and (max-width: 950px) {
                width: 160px;
                display: flex;
                margin-right: 2rem;
                align-items: center;
                flex-direction: row-reverse;
                justify-content: flex-end;
                padding: 9px 0;
              }
  
              small {
                display: block;
                font-family: $regular;
                font-size: 1.1rem;
                margin-top: 1.3rem;
                text-transform: uppercase;
                letter-spacing: 0.5px;
                @media screen and (max-width: 950px) {
                  margin-top: 0;
                  font-size: 9px;
                }
              }
              h3 {
                font-family: $bold;
                font-size: 1.4rem;
                text-transform: uppercase;
                padding-top: 1px;
                letter-spacing: 0.5px;
                @media screen and (max-width: 950px) {
                  font-size: 11px;
                }
              }
  
              .image {
                margin-top: 3rem;
                margin-bottom: 0.5rem;
                width: 2.5rem;
  
                @media screen and (max-width: 950px) {
                  margin-top: 0 !important;
                  margin-bottom: 0 !important;
                  margin-left: 10px;
                  margin-right: 10px;
                }
  
                img {
                  width: 100%;
                }
  
                //for the some classes
                &.man {
                  img {
                    width: 68%;
                  }
                }
  
                &.eye {
                  margin-bottom: 0.5rem;
                }
              }
            }
          }
        }
      }
    }
  }
  
  // table of the slae inner

  
  .transaction-container {

    max-width: 1756px;
    padding: 1.5rem 5rem;
    padding-bottom: 15em;
    // margin: auto;
    width: 80vw;
    @media screen and (max-width: 950px) {
      width: 100vw;
      padding-bottom: 4em;

    }
    @media screen and (max-width: 650px) {
      padding: 1.5rem 0 1.5rem 3rem;
      padding-bottom: 1em;

    }
    h1 {
      font-family: $bold;
      font-size: 2.8rem;
      color: #fff;
      text-transform: uppercase;
    }
    .transaction {
      overflow: hidden;
      @media screen and (max-width: 650px) {
        overflow-x: auto;
      }
  
      .table {
        display: flex;
        justify-content: space-between;
        margin-top: 3rem;
  
        @media screen and (max-width: 650px) {
          display: inline-flex;
        }
        .col {
          @media screen and (max-width: 650px) {
            width: 12rem;
          }
  
          .main-heading {
            font-family: $bold;
            font-size: 14px;
            color: #fff;
            margin-bottom: 2.2rem;
          }
  
          ul {
            list-style-type: none;
  
            li {
              margin-top: 1.5rem;
              color: #fff;
              font-family: $regular;
              font-size: 14px;
              letter-spacing: 1px;
            }
          }
        }
  
        .type {
          width: 10%;
          max-width: 30rem;
  
          @media screen and (max-width: 950px) and (min-width: 650px) {
            width: 2rem;
          }
  
          @media screen and (max-width: 650px) {
            width: 12rem;
          }
        }
  
        .date {
          @media screen and (max-width: 650px) {
            width: 17rem;
          }
        }
      }
    }
  }